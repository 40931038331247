export default class {
    constructor() {
        this.tab = '.js-tab';
        this.triggerItem = '.js-tab__trigger-item';
        this.init();
    }

    init() {
        const triggerItem = document.querySelectorAll(this.triggerItem);
        if (this.tab) {
            for (let i = 0; i < triggerItem.length; i++) {
                triggerItem[i].addEventListener('click', toggle);
            }

            function toggle() {
                const tabWrap = this.closest('.js-tab');
                const triggers = tabWrap.querySelectorAll('.js-tab__trigger-item');
                const contents = tabWrap.querySelectorAll('.js-tab__contents-item');
                for (let i = 0; i < triggers.length; i++) {
                    triggers[i].classList.remove('is-active');
                }
                for (let i = 0; i < contents.length; i++) {
                    contents[i].classList.remove('is-active');
                }
                this.classList.add('is-active');
                const aryTabs = Array.from(triggers);
                const index = aryTabs.indexOf(this);
                contents[index].classList.add('is-active');
            }

        }
    }
}